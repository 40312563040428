import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JSONEditor } from '@json-editor/json-editor';
import JSONEditorCs from '../../../assets/i18n/json-editor/cs.json'
import * as _ from "lodash";
import {MatSnackBar} from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

declare var Jodit;
declare var window;

@Component({
  selector: 'app-data-manager',
  templateUrl: './data-manager.component.html',
  styleUrls: ['./data-manager.component.css']
})
export class DataManagerComponent implements OnInit {
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
    window.Jodit = Jodit;
    JSONEditor.defaults.languages.cs = JSONEditorCs;
    JSONEditor.defaults.language = "cs";
  }
  private element;
  private editor;
  @Input() endpointSchemes;
  @Input() endpoints;
  @Input() base;
  public endpoint = null;
  public item = null;
  public dataList = [];
  public isNew = false;
  public search = "";

  ngOnInit() {

  }

  filterList(datalist) {
    return _.filter(datalist, (data) => {
      let label = this.getLabel(data);
      return new RegExp(this.search).test(label);
    })
  }

  save() {
    let output = this.editor.getValue();
    const errors = this.editor.validate();
    if (errors.length) {
      console.log("PROBLEM", errors)
      return;
    }
    if(this.isNew) {
      this.http.post<any>(this.base + this.endpoint.value.replace(/-/g,"_"), output).subscribe((data) => {
        this.snackBar.open('Uloženo', 'OK', {
          duration: 3000
        });
        this.selectEndpoint(this.endpoint);
      },
      (err) => {
        this.snackBar.open('Chyba při ukládání: ' + err, 'OK');
      });
    } else {
      this.http.patch<any>(this.base + this.endpoint.value.replace(/-/g,"_") + "/" + this.item, output).subscribe((data) => {
        this.snackBar.open('Uloženo', 'OK', {
          duration: 3000
        });
      },
      (err) => {
        this.snackBar.open('Chyba při ukládání: ' + err, 'OK');
      });
    }
  }

  selectEndpoint(endpoint) {
    this.isNew = false;
    this.item = null;
    this.endpoint = endpoint;
    this.getEndpointData();
  }

  selectItem(item) {
    this.item = item;
    this.isNew = false;
    this.http.get<any>(this.base + this.endpoint.value.replace(/-/g,"_") + "/" + item).subscribe((data) => {
      this.initEditor(data);
    })
  }

  newItem() {
    this.item = null;
    this.isNew = true;
    setTimeout(() => {
      this.initEditor();
    }, 1);
  }

  initEditor(value?) {
    this.element = document.getElementById('editor-holder');
    JSONEditor.defaults.callbacks.template = {
      "applyFilterOption": (jseditor,e) => {
        return e.item.filter ? e.item.value : "";
      }
    }
    this.editor  = new JSONEditor(this.element, {
      theme: "spectre",
      iconlib: "spectre",
      ajax: true,
      disable_edit_json: true,
      disable_collapse: true,
      disable_properties: false,
      disable_array_delete_all_rows: true,
      no_additional_properties: true,
      disable_array_delete_last_row: true,
      schema: this.addGlobals(this.endpointSchemes[this.endpoint.value])
    });
    if(value) {
      setTimeout(() => {
        this.editor.setValue(value);
      }, 500);
    }
  }

  getLabel(data) {
    return data.name ? data.name : data.title ? (data.title.cz ? data.title.cz : data.title) : data.id;
  }
  addGlobals(scheme) {
    return JSON.parse(JSON.stringify(scheme).replace(/\{\{SERVER_URL\}\}/g, environment.server));
  }

  getEndpointData() {
    this.http.get<any>(this.base + this.endpoint.value.replace(/-/g,"_")).subscribe((data) => {
      this.dataList = _.sortBy(data, this.getLabel);
    })
  }

  back() {
    if(this.item || this.isNew) {
      this.selectEndpoint(this.endpoint)
    } else{
      this.endpoint = null;
    }
  }

  getItemTitle(id: string): string {
    let item = _.find(this.dataList, ['id', id]);
    return this.getLabel(item);
  }

  delete(item) {
    let del = confirm("Opravdu chcete smazat tato data?");
    if(!del) {
      return;
    }
    this.http.delete<any>(this.base + this.endpoint.value.replace(/-/g,"_") + "/" + item).subscribe((data) => {
      this.selectEndpoint(this.endpoint);
      this.snackBar.open('Smazáno', 'OK', {
        duration: 3000
      });
    },
    (err) => {
      this.snackBar.open('Chyba při mazání: ' + err, 'OK');
    })
  }

}
