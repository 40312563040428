<div *ngIf="!selectedProject">
    <h1>Dostupné projekty</h1>
    <mat-grid-list cols="4" rowHeight="2:1">
        <mat-grid-tile *ngFor="let project of projects">
            <mat-card>
                <mat-card-header style="justify-content: center;">
                    <mat-card-title>{{project.name}}</mat-card-title>
                </mat-card-header>
                <mat-card-content style="justify-content: center;">
                    <button mat-raised-button color="primary" (click)="selectProject(project)">Zvolit</button>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<div *ngIf="selectedProject">
    <h1>Filtrování nahrávek</h1>
    <span *ngFor="let filter of recordingFilter; let i = index">
        {{filter.metaAttribute}} <input matInput type="text" [(ngModel)]="recordingFilter[i]['regex']" />
    </span>
    <button mat-raised-button color="primary" (click)="filterRecordings()">Filtrovat</button>
    <mat-list>
        <mat-list-item *ngFor="let recording of filteredAnnotations(_.keys(annotations[selectedProject.id])) | paginate:{itemsPerPage: 10, currentPage:p}">
                <h3 matLine>{{recordings[recording]?.url}}<mat-icon *ngIf="recordings[recording]?.url" (click)="i.play();$event.stopPropagation()"><span class="material-icons">
                        volume_up
                        </span></mat-icon>
                        <b>Atributy</b> <span *ngFor="let meta of recordings[recording]?.meta"> | {{meta.key}}:{{meta.value}}</span>
                </h3>
                <p matLine *ngFor="let annotation of annotations[selectedProject.id][recording]">                
                    {{annotation.annotator}}:
                    <span *ngFor="let label of annotation.labels; let i = index" (click)="setFinalAnnotation(recording, annotation.labels)">
                        {{labels[label]?.name}} 
                    </span>
                </p>
                <p matLine>
                    Finální anotace: 
                    <span *ngFor="let slot of recordings[recording]?.finalAnnotation[getFinalAnnotation(recording)]?.labels; let i = index">
                        <mat-icon (click)="addSlot(recording, i)"><span class="material-icons">add</span></mat-icon>
                        <select [(ngModel)]="recordings[recording]?.finalAnnotation[getFinalAnnotation(recording)]?.labels[i]">
                            <option *ngFor="let label of getProjectLabels()" [value]="label">{{labels[label].name}}</option>
                        </select>
                        <mat-icon (click)="removeSlot(recording, i)"><span class="material-icons">delete</span></mat-icon>
                    </span>
                    <mat-icon (click)="addSlot(recording)"><span class="material-icons">add</span></mat-icon>
                    <button mat-raised-button color="secondary" (click)="saveFinalAnnotation(recording, recordings[recording])">ULOŽIT</button>
                </p>
                <div style="display: none;">
                    <!--button mat-raised-button color="accent" ></button--> 
                    <audio #i>
                        <source [src]="recordings[recording]?.url" type="audio/wav">
                        Your browser does not support the audio element.
                    </audio>
                </div>
        </mat-list-item>
    </mat-list>
    <pagination-controls (pageChange)="p=$event"></pagination-controls>
    <button mat-raised-button color="secondary" (click)="back()">Zpět</button>
</div>