import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/project.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  public projects: Project[] = [];

  constructor(private http: HttpClient) { 
    this.http.get<Project[]>(environment.server + '/anophone_tasks').subscribe((data) => {
      this.projects = data;
    });
  }

  ngOnInit(): void {
  }

}
