import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Annotation } from 'src/app/models/annotation.model';
import { Project } from 'src/app/models/project.model';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { Label } from 'src/app/models/label.model';
import { Recording } from 'src/app/models/recording.model';

@Component({
  selector: 'app-recordings',
  templateUrl: './recordings.component.html',
  styleUrls: ['./recordings.component.scss']
})
export class RecordingsComponent implements OnInit {

  public labels: {[id:string]:Label} = {};
  public projects: {[id:string]:Project} = {};
  public recordings: {[id:string]:Recording} = {};
  public filteredRecordings: number[] = [];
  public recordingFilter: {metaAttribute: string, regex: string}[] = [];
  public _ = _;
  public p = null;

  constructor(private http: HttpClient) { 
    this.http.get<Label[]>(environment.server + '/anophone_labels').subscribe((data) => {
      this.labels = _.fromPairs(_.map(data  , (label: Label) => [label.id, label]));
    });
    this.http.get<Project[]>(environment.server + '/anophone_tasks').subscribe((data) => {
      this.projects = _.fromPairs(_.map(data  , (project: Project) => [project.id, project]));
    });
    this.http.get<Recording[]>(environment.server + '/anophone_recordings').subscribe((data) => {
      this.recordings = _.fromPairs(_.map(data, (recording: Recording) => [recording.id, recording]));
      this.filteredRecordings = _.map(this.recordings, (recording) => recording.id);
      let filter = _.uniq(_.flatten(_.map(data, (recording: Recording) => _.map(recording.meta, (meta) => meta.key))));
      this.recordingFilter = _.map(filter, (attr: string) => { return { metaAttribute: attr, regex: ".*"} });
    });
  }

  ngOnInit(): void {
  }

  filterRecordings() {
    let prefilter = this.recordings;
    if(this.recordingFilter.length) {
      prefilter = _.filter(this.recordings, (recording) => {
        let filterOut = false;
        for(let criteria of this.recordingFilter) {
          let metaAttributes = _.fromPairs(_.map(recording.meta, (meta) => [meta.key, meta.value.toString()]));
          if(!!!metaAttributes[criteria.metaAttribute] || !(new RegExp(criteria.regex).test(metaAttributes[criteria.metaAttribute]))) {
            filterOut = true;
          }
        }
        return !filterOut;
      });
    }
    this.filteredRecordings = _.map(
      prefilter,
      (recording: Recording) => recording.id
    );
  }

}
