<div *ngIf="_.isEmpty(recordings)">Načítání dat...</div>
<h1>Filtrování nahrávek</h1>
<span *ngFor="let filter of recordingFilter; let i = index">
    {{filter.metaAttribute}} <input matInput type="text" [(ngModel)]="recordingFilter[i]['regex']" />
</span>
<button mat-raised-button color="primary" (click)="filterRecordings()">Filtrovat</button>
<div class="item" *ngFor="let recording of filteredRecordings | paginate:{itemsPerPage: 10, currentPage:p}">
    <h3>{{recordings[recording]?.url}}<mat-icon *ngIf="recordings[recording]?.url" (click)="i.play();$event.stopPropagation()"><span class="material-icons">
            volume_up
            </span></mat-icon>
    </h3>
    <p>  
        <b>Atributy</b>
        <br/>
        <span *ngFor="let meta of recordings[recording]?.meta">{{meta.key}}:{{meta.value}}<br/></span>              
    </p>
    <p matLine>  
        <b>Finální anotace</b>
        <br/>
        <span *ngFor="let final of recordings[recording]?.finalAnnotation">
            {{projects[final.taskId]?.name}}: 
            <span *ngFor="let label of final.labels">{{labels[label]?.name}}</span>
            <br/>
        </span>              
    </p>
    <div style="display: none;">
        <!--button mat-raised-button color="accent" ></button--> 
        <audio #i>
            <source [src]="recordings[recording]?.url" type="audio/wav">
            Your browser does not support the audio element.
        </audio>
    </div>
</div>
<pagination-controls (pageChange)="p=$event"></pagination-controls>
