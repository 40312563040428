import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Annotation } from 'src/app/models/annotation.model';
import { Project, Section, SectionLabel } from 'src/app/models/project.model';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { Label } from 'src/app/models/label.model';
import { Recording } from 'src/app/models/recording.model';

@Component({
  selector: 'app-annotation-dashboard',
  templateUrl: './annotation-dashboard.component.html',
  styleUrls: ['./annotation-dashboard.component.scss']
})
export class AnnotationDashboardComponent implements OnInit {

  public projects: Project[] = [];
  public selectedProject: Project = null;
  public annotations: {[project: string]:{[recording:string]:Annotation[]}} = {};
  public labels: {[id:string]:Label} = {};
  public recordings: {[id:string]:Recording} = {};
  public grouped: boolean = false;
  public filteredRecordings: number[] = [];
  public recordingFilter: {metaAttribute: string, regex: string}[] = [];
  public _ = _;

  constructor(private http: HttpClient) { 
    this.http.get<Project[]>(environment.server + '/anophone_tasks').subscribe((data) => {
      this.projects = data;
    });
    this.http.get<Annotation[]>(environment.server + '/anophone_annotations').subscribe((data) => {
      let taskAnnotations: {[task:string]:Annotation[]} = _.groupBy(data, (annotation: Annotation) => annotation.task);
      for(let task in taskAnnotations) {
        this.annotations[task] = _.groupBy(taskAnnotations[task], (annotation: Annotation) => annotation.recording);
      }
    });
    this.http.get<Label[]>(environment.server + '/anophone_labels').subscribe((data) => {
      this.labels = _.fromPairs(_.map(data  , (label: Label) => [label.id, label]));
    });
    this.http.get<Recording[]>(environment.server + '/anophone_recordings').subscribe((data) => {
      this.recordings = _.fromPairs(_.map(data, (recording: Recording) => [recording.id, recording]));
      for(let recordingId in this.recordings) {
        if(!this.recordings[recordingId].finalAnnotation) {
          this.recordings[recordingId].finalAnnotation = [];
        }
      }
      this.filteredRecordings = _.map(this.recordings, (recording) => recording.id);
      let filter = _.uniq(_.flatten(_.map(data, (recording: Recording) => _.map(recording.meta, (meta) => meta.key))));
      this.recordingFilter = _.map(filter, (attr: string) => { return { metaAttribute: attr, regex: null} });
    });
  }

  getFinalAnnotation(recordingId: string) {
    if(!this.recordings[recordingId].finalAnnotation) {
      this.recordings[recordingId].finalAnnotation = [];
    }
    let index = _.findIndex(this.recordings[recordingId].finalAnnotation, (item) => item.taskId == this.selectedProject.id);
    if(index == -1) {
      this.recordings[recordingId].finalAnnotation.push({
        taskId: this.selectedProject.id,
        labels: []
      });
      index = this.recordings[recordingId].finalAnnotation.length - 1;
    }
    return index;
  }

  addSlot(id: string, i = null) {
    if(i === null) {
      this.recordings[id].finalAnnotation[this.getFinalAnnotation(id)].labels.push(null);
    } else {
      this.recordings[id].finalAnnotation[this.getFinalAnnotation(id)].labels.splice(i, 0, null);
    }
  }

  removeSlot(id: string, i) {
    this.recordings[id].finalAnnotation[this.getFinalAnnotation(id)].labels.splice(i, 1);
  }

  saveFinalAnnotation(id: string, recording: Recording) {
    this.http.put<Recording>(environment.server + '/anophone_recordings/' + id, recording).subscribe((data) => {
      console.log(data);
    });
  }

  setFinalAnnotation(id, annotationLabels) {
    this.recordings[id].finalAnnotation[this.getFinalAnnotation(id)].labels = _.cloneDeep(annotationLabels);
  }

  ngOnInit(): void {
  }

  getProjectLabels() {
    if(!this.selectedProject) {
      return [];
    }
    return _.flatten(_.map(this.selectedProject.sections, (section: Section) => _.map(section.labels, (sectionLabel: SectionLabel) => sectionLabel.label)));
  }

  selectProject(id) {
    this.selectedProject = id;
    for(let recordingId in this.recordings) {
      if(!_.find(this.recordings[recordingId].finalAnnotation, (item) => item.taskId == this.selectedProject.id)) {
        this.recordings[recordingId].finalAnnotation.push({
          taskId: this.selectedProject.id,
          labels: []
        });
      }
    }
  }

  back() {
    this.selectedProject = null;
  }

  filteredAnnotations(recordingIds: string) {
    console.log(recordingIds, this.filteredRecordings)
    return _.filter(recordingIds, (recordingId) => this.filteredRecordings.includes(recordingId))
  }

  filterRecordings() {
    let prefilter = this.recordings;
    if(this.recordingFilter.length) {
      prefilter = _.filter(this.recordings, (recording) => {
        let filterOut = false;
        for(let criteria of this.recordingFilter) {
          let metaAttributes = _.fromPairs(_.map(recording.meta, (meta) => [meta.key, meta.value.toString()]));
          if(!!!metaAttributes[criteria.metaAttribute] || !(new RegExp(criteria.regex).test(metaAttributes[criteria.metaAttribute]))) {
            filterOut = true;
          }
        }
        return !filterOut;
      });
    }
    this.filteredRecordings = _.map(
      prefilter,
      (recording: Recording) => recording.id
    );
  }

}
