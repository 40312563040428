<mat-toolbar color="primary">
    <span>ANOPHONE</span>
    <span class="example-spacer"></span>
    <button mat-flat-button color="primary" class="example-icon favorite-icon" routerLink="/recordings">
        DATABÁZE NAHRÁVEK
    </button>
    <button mat-flat-button color="primary" class="example-icon favorite-icon" routerLink="/intro">
        ANOTAČNÍ ÚLOHY
    </button>
    <button mat-flat-button color="primary" routerLink="/admin">
        SPRÁVA ANOTACÍ
    </button>
    <button mat-flat-button color="primary" routerLink="/admin/anophone">
        ADMINISTRACE
    </button>
</mat-toolbar>
<router-outlet></router-outlet>
<mat-toolbar color="primary">
    Tento web byl financován projektem CZ.02.2.69/0.0/0.0/19_073/0016943 Interní grantová agentura Masarykovy univerzity
    MUNI/IGA/1225/2020, L2 pronunciation of Czech (linguistic annotation system)
</mat-toolbar>
