<h1>Dostupné projekty</h1>
<mat-grid-list cols="4" rowHeight="2:1">
    <mat-grid-tile *ngFor="let project of projects">
        <mat-card>
            <mat-card-header style="justify-content: center;">
                <mat-card-title>{{project.name}}</mat-card-title>
            </mat-card-header>
            <mat-card-content style="justify-content: center;">
                <button mat-raised-button color="primary" [routerLink]="['/annotate', project.id]">Anotovat</button>
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>