<div *ngIf="currentRecording" style="padding: 10px;">
    <mat-card>   
        <mat-card-header style="justify-content: center;">
            <mat-card-title>Anotovaná nahrávka</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div style="display: flex; flex-direction: row;flex-wrap: wrap;">
                <div style="flex: 1 1 45%; padding: 5px;">
                    <h2>Nahrávka {{currentRecording?.url}}</h2>
                    <div #waveContainer id="waveContainer"></div>
                    <div #spectrumContainer id="spectrumContainer"></div>

                    <!--button mat-raised-button color="primary" (click)="currentRecording?.audio.play()">PŘEHRÁT</button-->
                </div>
                <div style="flex: 1 1 45%; padding: 5px;">
                    <h2>Anotace </h2>
                    <div style="border: white solid 2px; overflow: scroll; min-height: 50px;">
                        <span style="border: white solid 1px; padding: 5px;  display: inline-block; width: 5em;" *ngFor="let annotation of currentAnnotation; let i = index">
                            {{annotation?.name}} <button mat-icon-button (click)="removeFromAnnotation(i)"><mat-icon>delete</mat-icon></button>
                        </span>
                    </div>
                    <div *ngIf="project.hasSynthesis && currentAnnotationAudio">Syntéza je pouze orientační a nerozlišuje některé kontrasty správně.</div>
                    <audio controls *ngIf="project.hasSynthesis && currentAnnotationAudio">
                        <source [src]="trust(currentAnnotationAudio)" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            IDENTIFIKÁTOR ANOTÁTORA <input type="text" [(ngModel)]="annotatorId">
            <button mat-raised-button color="primary"  (click)="submitAnnotation()">ODESLAT ANOTACI</button>
            <button mat-raised-button color="accent" (click)="annotateNext()">ANOTOVAT DALŠÍ NAHRÁVKU</button>
        </mat-card-actions>
    </mat-card>

    <div style="padding: 5px; border: 2px solid black; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;">
        <h1 style="justify-content: center;">Instrukce</h1>
        <p>{{project?.description}} </p>
        <h1 style="justify-content: center;">Dostupné značky</h1>
        <div style="display: flex; flex-direction: row;flex-wrap: wrap;">
            <div *ngFor="let section of project.sections" style="flex: 1 1 30%; padding: 5px;">
                <h2>{{section.name}}</h2>
                <p>{{section.description}}</p>
                <div style="display: flex; flex-direction: row;flex-wrap: wrap;justify-content:center;align-items: center">
                    <div style="max-width: 200px; padding: 5px;" *ngFor="let label of section.labels">
                        <mat-card style="padding: 5px" [style.background]="label.color" [style.color]="label?.fontColor ? label?.fontColor : 'white'" (click)="addToAnnotation(labels[label.label])">
                            <mat-card-header style="justify-content: center;">
                                <mat-card-title>{{labels[label.label]?.name}} <mat-icon *ngIf="labels[label.label]?.description?.audio" (click)="i.play();$event.stopPropagation()"><span class="material-icons">
                                    volume_up
                                    </span></mat-icon></mat-card-title>
                            </mat-card-header>
                            <mat-card-content *ngIf="labels[label.label]?.description?.text">
                                {{labels[label.label]?.description?.text}}    
                            </mat-card-content>
                            <mat-card-actions>
                                <!--button mat-raised-button color="accent" ></button--> 
                                <audio #i>
                                    <source [src]="labels[label.label]?.description?.audio" type="audio/wav">
                                    Your browser does not support the audio element.
                                </audio>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
</div>




