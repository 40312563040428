import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { AnnotateComponent } from './pages/annotate/annotate.component';
import { AnnotationDashboardComponent } from './admin/annotation-dashboard/annotation-dashboard.component';
import { AdminDatabaseComponent } from './admin/database/admin-database.component';
import { IntroComponent } from './pages/intro/intro.component';
import { RecordingsComponent } from './pages/recordings/recordings.component';

const routes: Routes = [
  { path: '', redirectTo: '/intro', pathMatch: 'full'},
  { path: 'intro', component: IntroComponent},
  { path: 'recordings', component: RecordingsComponent},
  { path: 'admin', canActivate: [AuthGuard], component: AnnotationDashboardComponent},
  { path: 'admin/:module', canActivate: [AuthGuard], component: AdminDatabaseComponent },
  { path: 'annotate/:task', component: AnnotateComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
