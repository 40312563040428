import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Annotation } from 'src/app/models/annotation.model';
import { Label } from 'src/app/models/label.model';
import { Recording } from 'src/app/models/recording.model';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { Project } from 'src/app/models/project.model';
import { ActivatedRoute, Params } from '@angular/router';
import { TmplAstRecursiveVisitor } from '@angular/compiler';
import { DomSanitizer } from '@angular/platform-browser';
import { Howl } from 'howler';
import WaveSurfer from 'wavesurfer.js'
import { ReplaySubject } from 'rxjs';
@Component({
  selector: 'app-annotate',
  templateUrl: './annotate.component.html',
  styleUrls: ['./annotate.component.scss']
})
export class AnnotateComponent implements OnInit, AfterViewInit {

  public annotatorId: string;
  public labels: {[labelId: string]: Label};
  public recordings: Recording[];
  public project: Project;
  public currentRecording: Recording;
  public currentAnnotation: Label[] = [];
  public currentAnnotationAudio: string = null;
  public dataLoaded = new ReplaySubject();
  @ViewChild('waveContainer') waveContainer: ElementRef<HTMLElement>;
  @ViewChild('spectrumContainer') spectrumContainer: ElementRef<HTMLElement>;

  public decodeURIComponent = decodeURIComponent;

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private sanitizer:DomSanitizer) { 
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params["task"]) {
        this.http.get<Project>(environment.server + '/anophone_tasks/' + params["task"]).subscribe((data) => {
          this.project = data;
          this.http.get<Label[]>(environment.server + '/anophone_labels').subscribe((data) => {
            this.labels = _.fromPairs(_.map(data, (label) => [label.id, label]));
            console.log(this.labels);
          });
          this.http.get<Recording[]>(environment.server + '/anophone_recordings').subscribe((data) => {
            this.recordings = _.filter(data, (recording) => {
              let filterOut = false;
              if(this.project.recordingFilter && this.project.recordingFilter.length) {
                for(let criteria of this.project.recordingFilter) {
                  let metaAttributes = _.fromPairs(_.map(recording.meta, (meta) => [meta.key, meta.value]));
                  if(!!!metaAttributes[criteria.metaAttribute] || !(new RegExp(criteria.regex).test(metaAttributes[criteria.metaAttribute]))) {
                    filterOut = true;
                  }
                }
              }
              return !filterOut;
            });
            this.dataLoaded.next();
          });
        });
      }
    })
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.dataLoaded.subscribe(() => {
      this.annotateNext();
    })

  }

  addToAnnotation(label) {
    this.currentAnnotation.push(label);
    this.getSynthesis();
  }

  removeFromAnnotation(index) {
    this.currentAnnotation.splice(index, 1);
    this.getSynthesis();
  }

  submitAnnotation() {
    if(!this.annotatorId) {
      alert("Vyplňte identifikátor anotátora");
      return;
    }
    if(!this.currentAnnotation.length) {
      alert("Přiřaďte alespoň jeden segment");
      return;
    }
    this.http.post<Annotation>(environment.server + '/anophone_annotations', {
      id: this.annotatorId + new Date().getTime().toString(),
      annotator: this.annotatorId,
      labels: this.currentAnnotation.map((val) => val.id),
      recording: this.currentRecording.id,
      task: this.project.id
    }).subscribe((data) => {
      console.log("NEW", data);
      alert("Anotace odeslána");
    });
  }


  trust(html) {
    return this.sanitizer.bypassSecurityTrustUrl(html);
  }

  annotateNext() {
    this.currentRecording = null;
    this.currentAnnotation = [];  
    this.http.get<Annotation[]>(environment.server + '/anophone_annotations').subscribe((data) => {
      let annotations = data;
      let recordingIds = this.recordings.map((val) => val.id);
      let annotatedRecordings = annotations.map((val) => val.recording);
      let recordingCounts = _.countBy(annotatedRecordings);
      let recordingObjects = [];
      for(let recording of recordingIds) {
        if(!recordingCounts[recording]) {
          recordingCounts[recording] = 0;
        }
        recordingObjects.push({
          key: recording,
          count: recordingCounts[recording]
        })
      }
      let orderedRecordings = _.orderBy(_.shuffle(recordingObjects), ['count'], ['asc']);
      let selectedRecordingId = _.first(orderedRecordings).key;
      this.currentRecording = _.find(this.recordings, ['id', selectedRecordingId]);
      this.currentRecording.audio = new Howl({
        src: [this.currentRecording.url]
      });
      this.currentRecording.audio.on("loaderror", (a, b) => {
        console.log(a, b);
      })
      this.currentRecording.audio.on("load",() => {
      // Create an instance of WaveSurfer
      const ws = WaveSurfer.create({
        container: document.getElementById("waveContainer"),
        waveColor: 'rgb(200, 0, 200)',
        progressColor: 'rgb(100, 0, 100)',
        url: this.currentRecording.url,
        sampleRate: 22050,
      })

      // Initialize the Spectrogram plugin
      ws.registerPlugin(
        WaveSurfer.spectrogram.create({
          container: document.getElementById("spectrumContainer"),
          labels: true,
          height: 256,
        }),
      )

      // Play on click
      ws.once('interaction', () => {
        ws.play()
      })
      })


    });
  }

  public getSynthesis() {
    this.currentAnnotationAudio = null;
    if(!this.currentAnnotation.length) {
      return;
    }
    let data = {
      text: _.join(this.currentAnnotation.map((label) => label.name), ""),
      voice: 'Tatyana',
    };
    this.http.post<any>("https://iawll6of90.execute-api.us-east-1.amazonaws.com/production", JSON.stringify(data)).subscribe((data) => {
      this.currentAnnotationAudio = "data:audio/mpeg;base64," + data;
    });
    console.log(data.text);
  }

}

